//import ReactGA from 'react-ga';
import ReactGA from "react-ga4";
import log from './log';

const GA = {
  pageview: () => {
    if (process.env.REACT_APP_ENV !== 'prod'){
      //return;
    }

    //ReactGA.initialize('UA-166037494-1');
    ReactGA.initialize(process.env.REACT_APP_GA4);
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  },
  track: (e) => {
    log.trace(e);

    if (process.env.REACT_APP_ENV !== 'prod'){
      //return;
    }

    //ReactGA.initialize('UA-166037494-1');
    ReactGA.initialize(process.env.REACT_APP_GA4)
    
    // if conversion event
    if (e.action.startsWith('proUnlocked_')){
      log.traceTag('send proUnlocked_ event', e)
      ReactGA.event(
        e.action, {
          value: e.value
        })
      return;
    }

    // is custom event
    ReactGA.event({
      category: e.category,
      action: e.action,
      label: e.label,
      value: e.value
    });
  }
}

export default GA;
