import React from 'react';
import { Helmet } from "react-helmet";
import { Redirect, Link} from 'react-router-dom';

import Section from './components/Section';
import Uvp from './components/Uvp';
import Footer from './components/Footer';
import SignInButton from './components/buttons/SignInButton';
import Pricing from './components/Pricing';
import Features from './components/Features';
import { GoogleOAuthProvider } from '@react-oauth/google';

import auth from './services/auth';

import '@rmwc/tooltip/styles';
import '@rmwc/button/styles';

function Landing(){
  console.log(process.env.NODE_ENV)

  if (auth.isLogged()) {
    return <Redirect to={{
      pathname: '/dashboard'
    }}/>;
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <div className="container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Global Real-Time UV Index REST JSON API | OpenUV.io</title>
        <meta name="description" content="Free Real-Time Gold, Silver Platinum & Palladium Spot Prices RESTful JSON API. Live Feed of Gold & Silver Spot Prices from FOREX, SAXO, OANDA and IDC Exchanges."></meta>
      </Helmet>
      <Section 
        showScrollTo={true}
        showNav={false}
        header='OpenUV.io is'
        landing
        subtitle="100% Free Real-Time UV Index JSON API for inspiring Devs, Innovators and Smart Home Enthusiasts">
        <Uvp></Uvp>
        <div className="row mt-4 mb-5">
        <div className="col-12 text-center">
          <SignInButton>
          </SignInButton>
        </div>
      </div>
      </Section>
      <Section 
        showScrollTo={true}
        showNav={false}
        header='Made for Devs, Innovators and Smart Home Enthusiasts'
        title='Sun Awareness. Redefined.' 
        subtitle='Empower your app with indispensable Sun Safety details and fight over Skin Cancer as never before'>
        <Features></Features>
        <div className="row mt-4 mb-5">
        <div className="col-12 text-center">
          <SignInButton>
          </SignInButton>
        </div>
      </div>
      </Section>
      <Section 
        showScrollTo={false}true
        showNav={false}
        header='Simple, Flat Pricing With No Surprises'
        title="Start Today. Free Forever."
        subtitle='No Card Required. Upgrade To Start-Up Plan in One Click via Stripe.'>
        <Pricing></Pricing>
        <div className="row mt-4 mb-5">
        <div className="col-12 text-center">
          <SignInButton>
          </SignInButton>
        </div>
      </div>
        <Footer></Footer>
      </Section>
    </div>
    </GoogleOAuthProvider>
  );
}

export default Landing;
