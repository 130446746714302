import React from 'react';
import withSizes from 'react-sizes';
import {Tooltip} from '@rmwc/tooltip'

import MouseScroll from './MouseScroll';
import CWIcon from '../components/icons/CWIcon';

import '../css/section.css'

function Section(props) {
  return (
    <div className={props.isDesktop ? props.background : ''}>
      <section className={props.isDesktop ? "mx-4" : "mx-4"}>
        <div className={props.fullScreen ? (
          props.isDesktop ? "vh-100" : "my-5") : "my-5"}>
          <div className="row h-100 justify-content-center align-items-center">
            <div className="col-12 text-center">
              <div className="row mt-5">
                <div className="col-12 my-auto text-center">
                  {props.landing ?
                   <div className='row justify-content-center'>
                    <div className='col justify-content-center my-auto'>
                    <CWIcon theme='dark' size={30}/>&nbsp;&nbsp;&nbsp;
                      <span className="lead text-muted">
                        <span>OpenUV</span> is Minimalistic
                      </span> 
                      </div>
                      </div>
                      :
                      <span className="lead text-muted">{props.header}</span>
                  }
                  {props.landing ?
                    [
                      <h1 className={ props.isDesktop ? "display-4 my-3" : "display-5 my-3"}><span className="">Global Real-Time</span> <span className="">UV Index</span> JSON API</h1>, 
                      <h2 className="lead subtitle text-muted my-2">{props.subtitle}</h2>
                    ] :
                    [
                      <h2 className={ props.isDesktop ? "display-4 my-3" : "display-5 my-3"}>{props.title}</h2>,
                      <h3 className="lead text-muted my-2">{props.subtitle}</h3>
                    ]
                  } 
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  {props.children}
                </div>
                {props.showScrollTo &&
                  <MouseScroll></MouseScroll>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

Section.defaultProps = {
  showNav: false,
  showScrollTo: true,
  fullScreen: 'vh-100',
  h1: false
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
  isDesktop: width >= 1024
})

export default withSizes(mapSizesToProps)(Section);
