const log = {
  trace: (message) => {
    if (process.env.REACT_APP_ENV !== 'dev'){
      //log(process.env.REACT_APP_ENV);
      return;
    }
    console.log(message);
  },
  traceTag: (tag, message) => {
    if (process.env.REACT_APP_ENV !== 'dev'){
      //log(process.env.REACT_APP_ENV);
      return;
    }
    console.log(tag);
    console.log(message);
  }
}

export default log;