import React from 'react';

function DownloadIcon(props){
  return (
    <>
      <img data-effect="solid" data-class="tooltip" style={{paddingBottom: props.padding+'px'}} src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='${props.disabled ? 'gray' : '%234788d6'}' width='18px' height='18px'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z'/%3E%3C/svg%3E`}/>
    </>
  );
};

DownloadIcon.defaultProps = {
  padding: 0,
  disabled: false
}

export default DownloadIcon;