import React from 'react';
import {Link} from 'react-router-dom';

function Footer(){
  return (
    <div>
      <div className="row justify-content-center align-items-center mt-4">
        <div className="col-12 text-center justify-content-center align-items-center">
          <span className="small text-muted">
            <span className="small">
              <Link to="/">Home</Link>
              •&nbsp;
              <a target="_blank" href="/kb/tos">Terms of Service</a>
              •&nbsp;
              <a target="_blank" href="/kb/privacy">Privacy</a>
              •&nbsp;
              <a target="_blank" href='mailto:support@fullstack.cafe'>Contacts</a>
            </span>
          </span>
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 text-center justify-content-center align-items-center">
          <span className="small text-muted">
            <span className="small">
            OpenUV.io is the missing minimalistic Real-Time JSON UV Index API for awesome Developers, Innovators and Smart Home Enthusiasts you've been looking for
            </span>
          </span>
        </div>
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-12 text-center justify-content-center align-items-center">
          <span className="small text-muted">
            <span className="small">
              © 2016-2022, OpenUV.io, FullStack Cafe Ltd.
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;