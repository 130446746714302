import React, { Suspense, lazy }  from 'react';
import ReactDOM from 'react-dom';
import Notifications from 'react-notify-toast';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './css/App.css';

import Landing from './Landing';

const BlogPost = lazy(() => import('./components/BlogPost'));
const Dashboard = lazy(() => import('./components/Dashboard'));

const routing = (
  <Router>
    <Notifications />
    <Suspense fallback={
       <div className="bg">
        <section className="mx-5">
          <div className="vh-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-12 text-center">
                <div className="spinner">
                    <div className="bounce1"></div>
                    <div className="bounce2"></div>
                    <div className="bounce3"></div>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </div>      
      }>
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/stripe/success" component={Dashboard} />
        <Route exact path="/stripe/canceled" component={Dashboard} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/kb/:url" component={BlogPost} />
      </Switch>
    </Suspense>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

