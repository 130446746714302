import jwt from 'jwt-decode';
import PubSub from 'pubsub-js'
import log from './log'

import fetcher from '../services/fetcher';

const Auth = {
  getUser: () => {
    const token = localStorage.token;
    if (token){
      const user = jwt(token);
      if (user) return user;
      else return null;
    }
    return null;
  },
  signIn: async (name, email) => {
    const data = await fetcher.signInUser(name, email);
    //console.log(data);
    // store token
    const user = jwt(data.token);
    console.log(user);
    localStorage.setItem('token', data.token);
    PubSub.publish('LOGIN', 'signin');
    return Auth.getUser();
  },
  isLogged: () => {
    const user = Auth.getUser()
    return (user != null);
  },
  refreshUser: async () => {
    var user = Auth.getUser();
    log.trace('refreshUser', user);
    const data = await fetcher.refreshToken(user.google.name, user.google.email)
    localStorage.setItem('token', data.token);
    PubSub.publish('REFRESH', 'refreshed');
    return Auth.getUser();
  },
  logOut: () => {
    localStorage.removeItem('token');
    PubSub.publish('LOGOUT', 'signout');
    return;
  },
  unsubscribe: async (email) => {
    const result = await fetcher.unsubscribe(email);
    PubSub.publish('UNSUB', 'unsubscribed');
    return result;
  }
}

export default Auth;