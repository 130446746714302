import React from 'react';

import '../css/spinner.css';

function Spinner(props){
  return (
    <div className={props.small ? 
        (props.white ? 
          'spinner-button-white' : 'spinner-button') : 'spinner'}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
};

Spinner.defaultProps = {
  small: false 
};

export default Spinner;