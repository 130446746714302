import React from 'react';

function Features() {
  return (
    <div>
      <div className="row mt-3 mb-5 mx-5 text-center">
      <div className="col-md-4 my-2">
          <div className="my-3 mb-4 mt-3">
            <img
              src="https://img.icons8.com/ios/64/000000/sunset.png"
              width="50px"
              alt="Real-Time UV Index"/>
          </div>
          <h4><span className="hl-gold">Real-Time</span> UV Index</h4>
          <span className="text-muted">Based on publications of The Royal Netherlands Meteorological Institute (KNMI) and The National Institute of Water and Atmospheric Research (NIWA) of New Zealand.</span>
        </div>
        <div className="col-md-4 my-2">
          <div className="my-3 mb-4 mt-3">
            <img
              src="https://img.icons8.com/ios/64/000000/barometer--v2.png"
              width="50px"
              alt="Hourly UV Index Forecast"/>
          </div>
          <h4>Hourly <span className="hl-silver">UV Index Forecast </span></h4>
          <span className="text-muted">Get UV Index Forecast for any date and at any location. Correct clear sky UV Index using clouds absorption and area reflection factors. Recommend ideal protection time to your users based on their skin type.</span>
        </div>
        <div className="col-md-4 my-2">
          <div className="my-3 mb-4 mt-3">
            <img
              src="https://img.icons8.com/ios-glyphs/64/000000/source-code.png"
              width="50px"
              alt="Crafted for Developers"/>
          </div>
          <h4>Crafted for <span className="hl-gold">Developers</span></h4>
          <span className="text-muted">Just 3 minutes time to your first "Hello API". No POST endpoints. CORS supported. Sign In now and start test API today.</span>
        </div>
      </div>
    </div>
  );
};

export default Features;