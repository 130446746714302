import React from 'react';
import Slider from 'react-slick';
import withSizes from 'react-sizes';

import CodeBlock from './CodeBlock'

import '../css/Uvp.css';

function Uvp(props){

  const request = `curl -X GET 'https://api.openuv.io/api/v1/uv?lat=-33.34&lng=115.342' \n     -H 'x-access-token: Your_API_Key'`;

  const response = JSON.stringify({
    "uv": 5.6377,
		"uv_time": "2018-01-20T13:25:40.562Z",
		"uv_max": 12.5159,
		"uv_max_time": "2018-01-20T04:29:18.813Z",
		"ozone": 290.1,
		"ozone_time": "2018-01-20T01:50:25.780Z"
  }, null, 2);

  return (
    <div className="uvp-parent">
      <>
          {props.isDesktop && 
            <div className="uvp-preview mt-3 mb-4 row justify-content-center align-items-center">
              <div style={{width: 700+'px'}}>
                <div>
                  <CodeBlock language="js" value={`// API Request:
${request}`}/>
                  <CodeBlock language="js" value={`// API Response: 
${response}`}/>
                </div>
              </div>
            </div>
          }
          {props.isMobile && 
            <div>
              <CodeBlock language="js" value={request}/>
             <CodeBlock language="js" value={response}/>
            </div>
          }
        </>
      </div>
  );
};

Uvp.defaultProps = {
  landing: true 
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 700,
  isDesktop: width >= 700
})


export default withSizes(mapSizesToProps)(Uvp);