import React from 'react';

function GoogleIcon(props){

  let svg = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='18pt' height='18pt' viewBox='0 0 18 18' version='1.1'%3E%3Cg id='surface14711619'%3E%3Cpath style=' stroke:none;fill-rule:nonzero;fill:"
  svg = svg + props.color
  svg = svg + ";fill-opacity:1;' d='M 9.410156 7.679688 L 9.410156 10.546875 L 13.492188 10.546875 C 12.957031 12.28125 11.507812 13.523438 9.410156 13.523438 C 6.910156 13.523438 4.882812 11.5 4.882812 9 C 4.882812 6.5 6.910156 4.476562 9.410156 4.476562 C 10.53125 4.476562 11.558594 4.886719 12.347656 5.566406 L 14.460938 3.457031 C 13.128906 2.242188 11.355469 1.5 9.410156 1.5 C 5.265625 1.5 1.90625 4.859375 1.90625 9 C 1.90625 13.140625 5.265625 16.5 9.410156 16.5 C 15.707031 16.5 17.09375 10.613281 16.476562 7.6875 Z M 9.410156 7.679688 '/%3E%3C/g%3E%3C/svg%3E"

  return (
    <>
      <img src={svg}/>
    </>
  );
};

GoogleIcon.defaultProps = {
  color: 'rgb(71,136,214)'
  //color: "rgb(255,255,255)"
}

export default GoogleIcon;