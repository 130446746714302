import React from "react";
import PropTypes from "prop-types";

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/prism/javascript';
import php from 'react-syntax-highlighter/dist/esm/languages/prism/php';
import python from 'react-syntax-highlighter/dist/esm/languages/prism/python';
import csharp from 'react-syntax-highlighter/dist/esm/languages/prism/csharp';
import swift from 'react-syntax-highlighter/dist/esm/languages/prism/swift';
import bash from 'react-syntax-highlighter/dist/esm/languages/prism/bash';
import { cb } from 'react-syntax-highlighter/dist/esm/styles/prism';

SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('php', php);
SyntaxHighlighter.registerLanguage('py', python);
SyntaxHighlighter.registerLanguage('cs', csharp);
SyntaxHighlighter.registerLanguage('swift', swift);
SyntaxHighlighter.registerLanguage('curl', bash);

function CodeBlock(props) {
  return (
    <SyntaxHighlighter language={props.language} style={cb}>
      {props.value}
    </SyntaxHighlighter>
  );
}

CodeBlock.propTypes = {
  value: PropTypes.string.isRequired,
  language: PropTypes.string
};

CodeBlock.defaultProps = {
  language: null
};

export default CodeBlock;