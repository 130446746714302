const _ = {
  byOrder: (a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0),
  byTitle: (a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0),
  byName: (a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0),
  bySymbol: (a,b) => (a.symbol > b.symbol) ? 1 : ((b.symbol > a.symbol) ? -1 : 0),
  byLang: (a,b) => (a.lang > b.lang) ? 1 : ((b.lang > a.lang) ? -1 : 0),
  random: (items) => items[Math.floor(Math.random() * items.length)],
  shuffle: (items) => {
    for (let i = items.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [items[i], items[j]] = [items[j], items[i]];
    }
    return items;
  }
}

export default _;
