import React from 'react';

function Pricing() {
  return (
    <div>
      <div className="row mt-3 mb-5 mx-5 text-center">
        <div className="col-md-6 my-2">
          <div className="m-3">
            <img
              src="https://img.icons8.com/ios/90/000000/developer.png"
              width="50px"
              alt="OpenUV.io Pricing - Free Plan"/>
          </div>
          <h2><span className="hl-silver font-weight-bold">Personal</span> <span className="text-muted">Plan</span></h2>
          <h3 className="my-3 text-muted">Free Forever</h3>
          <span className="small">Up to <span className="font-weight-bold">50/reqs</span> per Day</span><br/>
          <span className="small">Hourly UV Index <span className="font-weight-bold">Forecast</span></span><br/>
        </div>
        <div className="col-md-6 my-2">
          <div className="m-3">
            <img
              src="https://img.icons8.com/ios/90/000000/for-beginner.png"
              width="50px"
              alt="OpenUV.io Pricing - Start-up Plan"/>
          </div>
          <h2><span className="hl-gold font-weight-bold">Start-Up</span> <span className="text-muted">Plan</span></h2>
          <h3 className="my-3"><sup className="text-muted">$</sup>15<sub>&nbsp;<span className="small text-muted">USD/month</span></sub></h3>
          <span className="small">Up to <span className="font-weight-bold">15000/reqs</span> per Day</span><br/>
          <span className="small">Hourly UV Index <span className="font-weight-bold">Forecast</span></span><br/>
          <span className="small"><span className="font-weight-bold">Historical & Future</span> Data</span><br/>
          <span className="small"><span className="font-weight-bold">Custom</span> Support</span><br/>
        </div>
      </div>
    </div>
  );
};

export default Pricing;