import React, { useState } from 'react';
import { Redirect} from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '@rmwc/button';
import withSizes from 'react-sizes';

import GoogleIcon from '../icons/GoogleIcon';

import log from '../../services/log';
import ga from '../../services/ga';
import auth from '../../services/auth';
import Spinner from '../Spinner';
import DownloadIcon from '../icons/DownloadIcon';


function SignInButton(props){

  const [isLogged, setIsLogged] = useState(false);
  const [loginInProgress, setLoginInProgress] = useState(false);

  const getUserProfileData = async (accessToken) => {
    const headers = new Headers()
    headers.append('Authorization', `Bearer ${accessToken}`)
    const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers
    })
    const data = await response.json();
    return data;
  }

  const authGoogleHandler = async (response) => {
    log.traceTag('Google Object', response)
    setLoginInProgress(true);

    // Get use profile details from Google API based on access_token
    const userProfile = await getUserProfileData(response.access_token);
    log.traceTag('userProfile', userProfile)

    if (userProfile){
      const user = await auth.signIn(userProfile.name, userProfile.email, userProfile.picture)
      log.traceTag('sign in user', user)
      if (props.subscribe && user){
        ga.track({
          category: 'Retention',
          action: 'subscribed',
        })
      }
      setIsLogged(true);
      setLoginInProgress(false);
    } else {
      setIsLogged(false);
      setLoginInProgress(false);
    }
  }

  const login = useGoogleLogin({
    onSuccess: authGoogleHandler,
    onError: authGoogleHandler
  });

  
  if (isLogged) {
    return <Redirect to={{
      pathname: '/dashboard'
    }}/>;
  }

  return (
    <>
      <Button data-effect="solid" data-place="bottom" data-class="tooltip" data-tip="Can't Sign In in Firefox or Brave? Enable Cross-Site (Third-Party) Cookies or try to use Chrome!" outlined={props.outlined} raised={props.raised} onClick={() => login()} disabled={props.disabled}>
        <GoogleIcon color={props.color}></GoogleIcon>&nbsp;&nbsp;
        {props.bold &&
          <b>{props.text}</b>
        }
        {!props.bold &&
          props.text
        }
        {loginInProgress &&
          <span>
            &nbsp;
            <Spinner white={true} small={true}></Spinner>
          </span>
        }
      </Button>  
      <div className="my-2">
        <span className='text-muted small'>Set Up In <b>30 Seconds</b>. <b>No Card</b> Required. </span> &nbsp;
      </div>
    </>     
  );
};

SignInButton.defaultProps = {
  text: 'Get FREE API Key or Sign In',
  outlined: true,
  raised: true,
  subscribe: false,
  bold: false,
  color: 'white'
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
  isDesktop: width >= 1024
})


export default withSizes(mapSizesToProps)(SignInButton);